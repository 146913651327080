:root {
  --dark: #051923;
  --light: #fdfcfb;
  --accent: #006494;
  --dark-accent: #003554;
  --bright-accent: #0582ca;
}

*,
.section-heading>* {
  font-family: 'Mohave', sans-serif;
  font-weight: 300;
}

p {
  font-size: larger;
}

.section-heading {
  font-size: 70px;
}

@media screen and (max-width: 991.98px) {
  .main-logo {
    max-width: 50vw;
    padding-top: 20px;
    margin: 0 auto;
  }

  .hero {
    background-size: contain;
  }

  #main-primary>img,
  #main-secondary>img {
    max-width: 70vw;
    margin: 10px;
  }

  #main-photos {
    margin-bottom: 25px;
  }

  .bio {
    display: block;
    margin: 0 auto;
    padding: 10px;
  }

  .experience {
    padding: 20px;
  }

  #training-primary>*,
  #consulting-primary>*,
  #witness-primary>*,
  #family-primary>* {
    width: 80vw;
    height: auto;
  }

  #training-secondary,
  #consulting-secondary,
  #witness-secondary,
  #family-secondary {
    flex-direction: column-reverse;
    justify-content: space-between;
  }

  #training-secondary>*,
  #consulting-secondary>*,
  #witness-secondary>*,
  #family-secondary>* {
    margin: 10px;
  }

  .witness-photos {
    display: block;
  }

  .services-list {
    display: block;
  }

  .services-detail-container {
    padding: 0px 10px;
  }

  .nav-link {
    margin: 10px 0px;
    padding: 0;
    width: 40%;
    font-size: large;
  }

  .detailed-services {
    margin-top: 35px;
  }

  .footer-left,
  .footer-right {
    display: block;
    margin: 0 auto;
  }

  #contact {
    scroll-margin-top: 80px;
  }

  .contact-sub-div {
    display: flex;
    justify-content: space-evenly;
  }

  .quote {
    margin: 10px 0px 10px 5px;
  }
}

@media screen and (min-width: 768px) {
  .nav-link {
    font-size: x-large;
  }
}

@media screen and (min-width: 992px) {

  .main-logo {
    max-width: 40vw;
  }

  #main-photos-div {
    max-width: 70vw;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
  }

  #main-primary>img {
    max-width: 50vw;
  }

  #main-secondary>img {
    max-width: 35vw;
  }

  .services-list {
    display: flex;
    justify-content: space-evenly;
  }

  .nav-link {
    font-size: xx-large;
  }

  .services-detail-container {
    padding: 0px 100px;
  }

  #training,
  #consulting,
  #witness,
  #family {
    width: 80%;
    margin: 0 auto;
  }

  .training-photos,
  .consulting-photos,
  .witness-photos,
  .family-photos {
    display: flex;
    justify-content: center;
  }

  #training-secondary,
  #consulting-secondary,
  #witness-secondary,
  #family-secondary {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .bio-heading {
    padding-bottom: 50px;
  }

  .inner-bio {
    padding: 25px;
    width: 85%;
  }

  .inner-bio {
    display: flex;
  }

  .experience,
  .photo-div {
    padding: 0px 50px;
  }

  .footer-row {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .footer-left,
  .footer-right {
    margin: 0px 50px;
  }

  .footer-left {
    text-align: left;
  }

  .footer-right {
    text-align: right;
  }

  .quote {
    margin: 20px 0px 0px 20px;
  }
}

/* ---------------------------------------- */

.App {
  width: 100vw;
  min-height: 100%;
  text-align: center;
  padding-top: 50px;
  position: relative;
  margin: auto;
}

.main {
  width: 100%;
}

/* --------------------- NAVBAR */

.navbar {
  background-color: var(--dark);
}

#nav-logo {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(209deg) brightness(105%) contrast(108%);
  height: 50px;

}

.nav-link {
  color: var(--light);
  text-decoration: none;
  font-family: 'Mohave', sans-serif;
}

.nav {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.navbar-brand {
  margin-left: 15px;
}

.nav-link:hover,
.nav-link:focus {
  color: var(--bright-accent);

}

/* --------------------- HERO */

.main-logo {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(209deg) brightness(105%) contrast(108%);
  margin: 50px 0px;
}

.hero {
  background-image: url('../static/images/blurry-gradient-haikei.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

#main-photos {
  background-color: var(--dark);
}


/* --------------------- SECTION HEADING */

.section-heading {
  justify-content: center;
}



/* --------------------- SERVICES */

.services-banner {
  font-family: 'Mohave', sans-serif;
  color: var(--bright-accent);
  padding-top: 10px;
}

#services-detail-div,
#bio-div,
#contact-div {
  scroll-margin-top: 30px;
}


.services-detail {
  width: 100%;
  background-color: var(--dark-accent);
  color: var(--light);
}

.services-nav {
  text-align: left;
}

.nav-link {
  align-self: flex-start;
}

.nav-link.active {
  color: var(--bright-accent) !important;
  padding: 5px 10px;
}

.services-detail>.section-heading,
.services-nav>.nav-link {
  color: var(--bright-accent);
}

.services-nav {
  min-width: 200px;
}

#services-detail-div,
.services-detail {
  background-color: var(--light);
  color: var(--dark);
}

#training,
#consulting,
#witness,
#family {
  background-color: var(--light);
  color: var(--dark);
  padding: 2%;
  font-size: large;
}

#EWPlink {
  color: var(--accent);
  font-weight: bolder;
}

#uniformed-outdoor {
  height: 400px;
}

/* --------------------- BIO */

.bio {
  background-color: var(--dark-accent);
  color: white;
}

.photo-text>p {
  margin: 0px;
  padding: 0px;
}

.cert {
  background-color: white;
  color: var(--accent);
  font-weight: 400;
  border: 1px white solid;
  padding: 3px 6px;
  border-radius: 5px;
}

/* --------------------- TESTIMONIAL */

.testimonial-component {
  background-color: var(--light);
  color: var(--dark);
  margin: 0 auto;
}

.inner-testimonial {
  max-width: 80vw;
  margin: 0 auto;
}

/* --------------------- INQUIRY */
.inquiry {
  color: var(--bright-accent);
  background-color: white;
}

form {
  text-align: center;
}

.message {
  height: 170px;
}

.btn-submit {
  background-color: var(--bright-accent);
  color: white;
}

/* --------------------- FOOTER */

#footer {
  background-color: var(--dark);
  color: var(--light);
  height: fit-content;
}

#footer-img {
  width: 100vw;
}

.footer-heading {
  font-family: 'Mohave', sans-serif;
  font-size: x-large;
}

.footer-row {
  align-items: center;
}

#linkedIn {
  color: white;
}

#footer-logo {
  height: 100px;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(209deg) brightness(105%) contrast(108%);
}

.footer-bottom {
  margin: 20px 10px;
}